export default {
	backend: {
		local: "http://localhost:3002/api/",
		dev: "https://system.dev.ai.nuwaru.com/api/",
		uat: "https://system.uat.ai.nuwaru.com/api/",
		prod: "https://system.ai.nuwaru.com/api/",
		apiVersion: "v1",
	},
	ai: {
		local: "http://127.0.0.1:3001/api/",
		dev: "https://backend.dev.ai.nuwaru.com/api/",
		uat: "https://backend.uat.ai.nuwaru.com/api/",
		prod: "https://backend.ai.nuwaru.com/api/",
		apiVersion: "v1",
	},
	auth: {
		local: "http://localhost:3000/",
		dev: "https://dev.ai.nuwaru.com/",
		uat: "https://uat.ai.nuwaru.com/",
		prod: "https://ai.nuwaru.com/",
	},
	blobStore: {
		local: "nuwai-uat",
		dev: "nuwai-uat",
		uat: "nuwai-uat",
		prod: "nuwai-uat"
	}
}