'use client';
import { useCallback, useRef, useState, useEffect } from 'react';

export default function NewChat({ hidePrompt, startChat }) {

    useEffect(() => {
    })

    return (
        <div className={`abacus-newChat-container ${hidePrompt}`}>
            <div className={`content`}>
                <h2 className={`heading`}>Get started:</h2>
                <div className={'body'}>
                    <div className={`start-tiles`} onClick={() => startChat("guideMe")}>
                        <h3>Guide me!</h3>
                        <p>
                            I can help you structure and refine your question to ensure I provide an accurate reponse that understands your context and situation.
                        </p>
                    </div>
                    <div className={`start-tiles`} onClick={() => startChat("jumpIn")}>
                        <h3>Jump right in!</h3>
                        <p>
                            Got a question ready to go? Let’s get straight to it!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}