'use client';
import { useCallback, useRef, useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { byPrefixAndName } from '@awesome.me/kit-7ab6be0559/icons'

import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ToggleSwitch from '@mui/material/Switch';

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { loginRequest } from "../../config/msalConfig";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import backend_api from "../../utils/BackendApiCaller"

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const marks = [
    {
        value: 0,
        label: 'Small'
    },
    {
        value: 50,
        label: 'Medium',
    },
    {
        value: 100,
        label: 'Large',
    },
];

const fontSize = [
    {
        value: 0,
        label: 'Aa'
    },
    {
        value: 100,
        label: 'Aa'
    }
]

const messageWidth = [
    {
        value: 0,
        label: 'Mobile'
    },
    {
        value: 50,
        label: 'Tablet',
    },
    {
        value: 100,
        label: 'Desktop',
    },
];

function valuetext(value) {
    return `${value}`;
}

const SettingSwitch = styled((props) => (
    <ToggleSwitch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 40,
    height: 24,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 20,
        height: 20
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E0E0E0',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const iOSBoxShadow =
    '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = styled(Slider)(({ theme }) => ({
    width: "180px",
    color: '#007bff',
    height: 5,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        boxShadow: '0 0 2px 0px rgba(0, 0, 0, 0.1)',
        '&:focus, &:hover, &.Mui-active': {
            boxShadow: '0px 0px 3px 1px rgba(0, 0, 0, 0.1)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
        '&:before': {
            boxShadow:
                '0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)',
        },
    },
    '& .MuiSlider-valueLabel': {
        fontSize: 12,
        fontWeight: 'normal',
        top: -6,
        backgroundColor: 'unset',
        color: theme.palette.text.primary,
        '&::before': {
            display: 'none',
        },
        '& *': {
            background: 'transparent',
            color: '#000',
            ...theme.applyStyles('dark', {
                color: '#fff',
            }),
        },
    },
    '& .MuiSlider-track': {
        border: 'none',
        height: 5,
    },
    '& .MuiSlider-rail': {
        opacity: 0.5,
        boxShadow: 'inset 0px 0px 4px -2px #000',
        backgroundColor: '#d0d0d0',
    },
    ...theme.applyStyles('dark', {
        color: '#0a84ff',
    }),
}));

export default function NavBar({ messageWidthSetter, dimSetter, profileSettings, graphTenantData, restoreAIHistory, thisWeekHistory }) {
    const [menuColour, setMenuColour] = useState("")
    const [menuColourHover, setMenuColourHover] = useState("")
    const [blueActive, setBlueActive] = useState(true);
    const [redActive, setRedActive] = useState(false);
    const [greenActive, setGreenActive] = useState(false);
    const [yellowActive, setYellowActive] = useState(false);

    const [menuState, setMenuState] = useState(false)
    const [menuSize, setMenuSize] = useState("")

    const [profileMenuSize, setProfileMenuSize] = useState("")
    const [historyMenuSize, setHistoryMenuSize] = useState("is-history")

    const [multiToolBody, setMultiToolBody] = useState("history")

    const [nightModeState, setNightMode] = useState(false);
    const [nightModeClass, setNightModeClass] = useState("")

    const [settingsMenuState, setMenuSettingsState] = useState(false);
    const [profileMenuState, setMenuProfileState] = useState(false);
    const [supportMenuState, setMenuSupportState] = useState(false);
    const [navSettings, setNavSettings] = useState({})
    const [profileLoaded, setProfileLoaded] = useState(false);

    const { instance, inProgress, accounts } = useMsal();

    const accessTokenRequestAPI = {
        scopes: [`${process.env.REACT_APP_ENTRA_APP_API}`],
        account: accounts[0],
    };

    const menuReSize = (e) => {
        console.log("Profile")
        if (menuState) {
            setMenuState(false);
            setMenuSize("")
            setHistoryMenuSize("is-history")
            setMultiToolBody("history")
            setMenuSettingsState(false)
            setMenuProfileState(false)
            setMenuSupportState(false)
            setProfileMenuSize("")
        } else {
            setMenuState(true);
            setMenuSize("is-active")
        }
    }

    const settingsMenuAction = (e) => {
    }

    const profileMenuAction = (e) => {
        if (profileMenuState) {
            setMenuSettingsState(false)
            setMenuProfileState(false)
            setMenuSupportState(false)

            setProfileMenuSize("")
            setHistoryMenuSize("is-history")
            setMultiToolBody("history")
            setMenuState(false);
            setMenuSize("")
        } else {
            setMenuSettingsState(false)
            setMenuProfileState(true)
            setMenuSupportState(false)

            setProfileMenuSize("is-profile")
            setHistoryMenuSize("")
            setMultiToolBody("profile")
            if (!menuState) {
                setMenuState(true);
                setMenuSize("is-active")
            }
        }
    }

    const supportMenuAction = (e) => {
    }

    const updateNavSettings = async (local_settings) => {
        if (profileLoaded) {
            instance
                .acquireTokenSilent(accessTokenRequestAPI)
                .then(async (accessTokenResponse) => {
                    let accessToken = accessTokenResponse.accessToken;
                    console.log("Local settings 3 ", local_settings)
                    console.log("TENANT ", graphTenantData)
                    await backend_api.updateProfile(accessToken, graphTenantData, local_settings)
                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect(accessTokenRequestAPI);
                    }
                    console.log(error);
                });
        }
    }

    const uiColorSelect = async (e) => {
        var target = e;
        var local_settings = navSettings

        if (target != "red" && target != "blue" && target != "yellow" && target != "green") {
            target = e.target.className.split(" ")
            target = target[1]
        }

        if (profileLoaded) {
            local_settings.profile_settings.system_color = target
            setNavSettings(local_settings)
            await updateNavSettings(local_settings)
        }

        switch (target) {
            case "blue":
                document.body.classList.add('blue');
                setMenuColour("blue")
                setBlueActive(true)

                setRedActive(false)
                setYellowActive(false)
                setGreenActive(false)

                document.body.classList.remove('red');
                document.body.classList.remove('green');
                document.body.classList.remove('yellow');
                break;
            case "red":
                document.body.classList.add('red');
                setMenuColour("red")
                setRedActive(true)

                setBlueActive(false)
                setYellowActive(false)
                setGreenActive(false)

                document.body.classList.remove('blue');
                document.body.classList.remove('green');
                document.body.classList.remove('yellow');
                break;
            case "yellow":
                document.body.classList.add('yellow');
                setMenuColour("yellow")
                setYellowActive(true)

                setBlueActive(false)
                setRedActive(false)
                setGreenActive(false)

                document.body.classList.remove('blue');
                document.body.classList.remove('green');
                document.body.classList.remove('red');
                break;
            case "green":
                document.body.classList.add('green');
                setMenuColour("green")
                setGreenActive(true)

                setBlueActive(false)
                setRedActive(false)
                setYellowActive(false)

                document.body.classList.remove('red');
                document.body.classList.remove('blue');
                document.body.classList.remove('yellow');
                break;
            default:
                setMenuColour("blue")
                break;
        }
    }

    const uiColorDefault = (e) => {
        setMenuColourHover("")
    }

    const nightMode = async (e) => {
        switch (e) {
            case true:
                setNightMode(true)
                document.body.classList.add('nightModeBody');
                setNightModeClass("nightMode")
                break;
            case false:
                setNightMode(false)
                document.body.classList.remove('nightModeBody');
                setNightModeClass("")
                break;
        }
    }

    const nightModeChanger = async (e) => {
        var local_settings = navSettings

        if (nightModeState) {
            setNightMode(false)
            document.body.classList.remove('nightModeBody');
            setNightModeClass("")

            if (profileLoaded) {
                local_settings.profile_settings.dark_mode = false
                setNavSettings(local_settings)
                await updateNavSettings(local_settings)
            }
        } else {
            setNightMode(true)
            document.body.classList.add('nightModeBody');
            setNightModeClass("nightMode")

            if (profileLoaded) {
                local_settings.profile_settings.dark_mode = true
                setNavSettings(local_settings)
                await updateNavSettings(local_settings)
            }
        }
    }

    const [sliderTracker, setSliderTracker] = useState(50);

    const uiSizeChanger = async (e, newValue) => {
        var local_settings = navSettings
        setSliderTracker(newValue)

        if (profileLoaded) {
            local_settings.profile_settings.ui_size = newValue
            setNavSettings(local_settings)
            await updateNavSettings(local_settings)
        }

        switch (newValue) {
            case 0:
                document.body.classList.add('small');

                document.body.classList.remove('medium');
                document.body.classList.remove('large');
                break;
            case 50:
                document.body.classList.add('medium');

                document.body.classList.remove('small');
                document.body.classList.remove('large');
                break;
            case 100:
                document.body.classList.add('large');

                document.body.classList.remove('medium');
                document.body.classList.remove('small');
                break;
        }
    }

    const [fontSizeTracker, setFontSizeTracker] = useState(0);

    const fontSizeChanger = async (e, newValue) => {
        var local_settings = navSettings
        setFontSizeTracker(newValue)

        if (profileLoaded) {
            local_settings.profile_settings.bold_text = newValue
            setNavSettings(local_settings)
            await updateNavSettings(local_settings)
        }

        switch (newValue) {
            case 0:
                document.body.classList.add('normal');

                document.body.classList.remove('bold');
                break;
            case 100:
                document.body.classList.add('bold');

                document.body.classList.remove('normal');
                break;
        }
    }

    const [messageWidthTracker, setMessageWidthTracker] = useState(50);

    const messageWidthChanger = async (e, newValue) => {
        var local_settings = navSettings
        setMessageWidthTracker(newValue)

        if (profileLoaded) {
            local_settings.profile_settings.message_width = newValue
            setNavSettings(local_settings)
            await updateNavSettings(local_settings)
        }

        switch (newValue) {
            case 0:
                if ((window.innerWidth - 150) > 450) {
                    messageWidthSetter({ maxWidth: `450px` })
                }

                dimSetter({ w: 450 })
                break;
            case 50:
                if ((window.innerWidth - 150) > 750) {
                    messageWidthSetter({ maxWidth: `750px` })
                }
                dimSetter({ w: 750 })
                break;
            case 100:
                messageWidthSetter({ maxWidth: `100%` })
                dimSetter({ w: 1080 })
                break;
        }
    }

    useEffect(() => {
        console.log("This is the settings", profileSettings.profile_settings)
        console.log("This is show startup", profileSettings.general.show_startup)
        if (!profileLoaded) {
            setNavSettings(profileSettings)
            uiColorSelect(profileSettings.profile_settings.system_color)
            nightMode(profileSettings.profile_settings.dark_mode)
            uiSizeChanger("", profileSettings.profile_settings.ui_size)
            fontSizeChanger("", profileSettings.profile_settings.bold_text)
            messageWidthChanger("", profileSettings.profile_settings.message_width)
            if (profileSettings.general.show_startup) {
                setMenuState(profileSettings.general.show_startup)
                setMenuSize("is-active")
                setProfileMenuSize("is-profile")

                setMenuSettingsState(false)
                setMenuProfileState(true)
                setMenuSupportState(false)

                setHistoryMenuSize("")
                setMultiToolBody("profile")
            }

            setProfileLoaded(true)
        }
    }, [profileLoaded, thisWeekHistory])

    return (
        <div className={`abacus-navbar-main ${menuSize} ${menuColour} ${menuColourHover}`}>
            <div className="abacus-navbar-logo">

            </div>
            <div className={`abacus-navbar-size-btn `} onClick={(e) => menuReSize(e)}>
                <div className={`abacus-navbar-hamburger ${menuSize}`}>
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                </div>

            </div>
            <div className={`abacus-navbar-multi-tool-body ${menuSize}`} >
                {multiToolBody == "history" ?
                    <div className={`abacus-navbar-multi-tool-container chat-history`}>
                        <div className="header">
                            <FontAwesomeIcon className={`abacus-navbar-multi-tool-icon chat-history ${menuSize}`} icon={byPrefixAndName.fal['clock-rotate-left']} />
                            <h2 className={`abacus-navbar-multi-tool-heading chat-history ${menuSize}`}>Chat History</h2>
                        </div>
                        <div className={`body ${menuState}`}>
                            {thisWeekHistory.length > 0 ?
                                <>
                                    <Accordion className={`history-accordion ${menuState}`}>
                                        <AccordionSummary
                                            expandIcon={<ArrowDownwardIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            className={`history-accordion-summary ${menuState}`}
                                        >
                                            <Typography className={`history-accordion-label ${menuState}`}>This Week</Typography>
                                        </AccordionSummary>
                                        {thisWeekHistory.map((history, index) =>
                                            <AccordionDetails className={`history-accordion-details ${menuState}`}>
                                                <Typography onClick={() => restoreAIHistory(history)} className={`history-accordion-details-title ${menuState}`}>
                                                    Chat {index}
                                                </Typography>
                                            </AccordionDetails>
                                        )}
                                    </Accordion>
                                </>
                                :
                                <p>No History</p>
                            }
                        </div>
                    </div>
                    : multiToolBody == "profile" ?
                        <div className={`abacus-navbar-multi-tool-container profile-settings`}>
                            <div className="abacus-navbar-multi-tool-header profile-settings">
                                <FontAwesomeIcon className={`abacus-navbar-multi-tool-icon profile-settings ${menuSize}`} icon={byPrefixAndName.fal['user']} />
                                <h2 className={`abacus-navbar-multi-tool-heading profile-settings ${menuSize}`}>Profile Settings</h2>
                            </div>
                            <div className="abacus-navbar-multi-tool-content profile-settings">
                                <p className={`abacus-navbar-multi-tool-content-headings ${menuSize}`}>
                                    System Color
                                </p>
                                <div className="abacus-navbar-multi-tool-content-colorSelector">
                                    <div className={`colorSelector blue ${blueActive}`} onClick={(e) => uiColorSelect(e)}></div>
                                    <div className={`colorSelector red ${redActive}`} onClick={(e) => uiColorSelect(e)}></div>
                                    <div className={`colorSelector green ${greenActive}`} onClick={(e) => uiColorSelect(e)}></div>
                                    <div className={`colorSelector yellow ${yellowActive}`} onClick={(e) => uiColorSelect(e)}></div>
                                </div>
                                <div className="abacus-navbar-multi-tool-content-uiChanger">
                                    <div className="abacus-navbar-multi-tool-content-nightMode">
                                        <p className={`abacus-navbar-multi-tool-content-headings ${menuSize}`}>
                                            Dark Mode
                                        </p>
                                        <FormGroup className="abacus-nightMode-modelGroup">
                                            <FormControlLabel className="abacus-nightMode-label" control={
                                                <SettingSwitch
                                                    size="small"
                                                    checked={nightModeState}
                                                    onChange={nightModeChanger}
                                                    name="darkMode"
                                                />}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="abacus-navbar-multi-tool-content-fontWeight">
                                        <p className={`abacus-navbar-multi-tool-content-headings ${menuSize}`}>
                                            Bold Text
                                        </p>
                                        <IOSSlider
                                            className='abacus-font-weight-slider'
                                            defaultValue={fontSizeTracker}
                                            getAriaValueText={valuetext}
                                            step={100}
                                            onChangeCommitted={fontSizeChanger}
                                            valueLabelDisplay="off"
                                            marks={fontSize}
                                        />
                                    </div>
                                </div>
                                <p className={`abacus-navbar-multi-tool-content-headings ${menuSize}`}>
                                    UI Size
                                </p>
                                <IOSSlider
                                    className='abacus-ui-size-slider'
                                    defaultValue={sliderTracker}
                                    getAriaValueText={valuetext}
                                    step={50}
                                    onChangeCommitted={uiSizeChanger}
                                    valueLabelDisplay="off"
                                    marks={marks}
                                />
                                <p className={`abacus-navbar-multi-tool-content-headings ${menuSize}`}>
                                    Message Width
                                </p>
                                <IOSSlider
                                    className='abacus-message-width-slider'
                                    defaultValue={messageWidthTracker}
                                    getAriaValueText={valuetext}
                                    step={50}
                                    onChangeCommitted={messageWidthChanger}
                                    valueLabelDisplay="off"
                                    marks={messageWidth}
                                />
                            </div>
                        </div> : <></>}
            </div>
            <div className={`abacus-navbar-options-body ${menuSize}`}>
                {/* <div className={`abacus-navbar-options-body-btn settings ${menuSize} ${settingsMenuState} `} onClick={(e) => settingsMenuAction(e)}>
                    <FontAwesomeIcon className={`abacus-navbar-options-icon settings ${menuSize}`} icon={byPrefixAndName.fasds['users-gear']} />
                    <button className={`abacus-navbar-options-btn ${menuSize}`}>
                        Admin
                    </button>
                </div> */}
                <div className={`abacus-navbar-options-body-btn profile ${menuSize} ${profileMenuState}`} onClick={(e) => profileMenuAction(e)}>
                    <FontAwesomeIcon className={`abacus-navbar-options-icon profile ${menuSize}`} icon={byPrefixAndName.fal['user']} />
                    <button className={`abacus-navbar-options-btn ${menuSize}`}>
                        Profile
                    </button>
                </div>
                <div className={`abacus-navbar-options-body-btn support ${menuSize} ${supportMenuState}`} onClick={(e) => supportMenuAction(e)}>
                    <FontAwesomeIcon className={`abacus-navbar-options-icon ${menuSize}`} icon={byPrefixAndName.fal['circle-info']} />
                    <button className={`abacus-navbar-options-btn ${menuSize}`}>
                        Support
                    </button>
                </div>
            </div>
        </div>
    )
}