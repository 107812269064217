'use client';
import { useCallback, useRef, useState, useEffect } from 'react';

export default function Launch({ setLaunchBody, hideLaunch, firstName, lastName}) {

    useEffect(() => {
    })

    return (
        <div className={`abacus-launch-container ${hideLaunch}`}>
            <div className={`content`}>
                <h2 className={`heading`}>Welcome {firstName} {lastName}</h2>
                <p>My name is AbacusAI; your personal tax and legal expert.</p>
                <p>
                    My primary information sources are the ATO Legal Database and the Australian Taxation Law Library. By default, my responses are in a style/length/detail that is typically provided by a large tax law or accounting firm, they do not include a preamble and aim to be definitive, conclusive and decisive.
                </p>
                <p>
                    If you wish to change the style of my responses, my sources or other settings, please refer to the 'AI Settings’ menu in the bottom left hand corner. Here you will also find access to my support and help functions.
                </p>
                <p>
                    Any settings you change will be saved and will remain the same for future sessions, you may also access previous sessions and refer to previous chats and sources with me from the menu on the left hand-side.
                </p>
            </div>
            <button className={`abacus-launch-button`} onClick={(e) => setLaunchBody(e)}>Don't show again</button>
        </div>
    )
}